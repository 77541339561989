<template>
  <div class="project top-fix">
    <van-nav-bar title="报工详情" fixed left-arrow @click-left="$router.back(-1)" />
    <div class="tables">
      <table border="1" style="width: 100%;">
        <thead>
          <tr>
            <th>任务号</th>
            <th>描述</th>
            <th>类型</th>
            <th>检查人</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in this.today" :key="index" class="today">
            <td style="width: 15%;">{{ item.no }}</td>
            <td style="width: 50%;">{{ item.describe }}</td>
            <td style="width: 20%;">{{ item.type}}</td>
            <td style="width: 20%;">{{ item.name }}</td>
          </tr>
          <tr v-for="(item, index) in this.yesterday" :key="index" class="yesterday">
            <td style="width: 15%;">{{ item.no }}</td>
            <td style="width: 50%;">{{ item.describe }}</td>
            <td style="width: 20%;">{{ item.type}}</td>
            <td style="width: 20%;">{{ item.name }}</td>
          </tr>
          <tr v-for="(item, index) in this.tasklist" :key="index">
            <td style="width: 15%;">{{ item.no }}</td>
            <td style="width: 50%;">{{ item.describe }}</td>
            <td style="width: 20%;">{{ item.type}}</td>
            <td style="width: 20%;">{{ item.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import TaskApi from "@/api/task.js";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      barcode: "",
      tasklist: false,
      today: false,
      projectid: "",
      yesterday: false
    };
  },
  computed: {
    ...mapState({
      dd: state => state.dd.dd
    })
  },
  methods: {
    gettasklist() {
      TaskApi.tasklist(this.project)
        .then(res => {
          this.tasklist = res.data.tasklist;
          this.today = res.data.today;
          this.yesterday = res.data.yesterday;
        })
        .catch(e => {
          this.tasklist = false;
        });
    }
  },
  mounted: function() {
    this.project = this.$route.params.no;
    this.gettasklist();
  }
};
</script>

<style lang="scss">
.today {
  background-color: #f5f971;
}
.yesterday {
  background-color: #6ff454;
}
.finish {
  font-size: small;
}
.project {
  padding: 50px 0 70px;
  .task_info {
    background: #f7f8fc;
    padding: 10px 0;
    .title {
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
    }
    .info {
      padding: 0 10px;
      > div {
        text-align: center;
        margin: 3px 0;
      }
      > div:first-child {
        text-align: left;
      }
      > div:last-child {
        text-align: right;
      }
    }
  }
  .item {
    .van-cell__title {
      flex: none;
      width: 7.2em;
    }
    .van-cell__value {
      text-align: left;
    }
    .red {
      color: #f00;
    }
    .blue {
      color: rgb(0, 47, 255);
    }
    .img {
      margin: 1%;
    }
  }
  .report {
    text-align: center;
    padding: 10px;
  }
  .finish_task {
    height: 80%;
    width: 80%;
    padding: 10px;
    .van-uploader {
      margin: 10px 0;
    }
    .submit {
      text-align: center;
      padding: 10px;
    }
  }
}
</style>
